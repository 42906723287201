import React from "react"
import { FormattedMessage } from "react-intl"
import SubLayout from "../components/Sublayout"
import { graphql } from "gatsby"
import SchoolCard from "../components/schools/SchoolCard"
import Container from "../components/structural/Container"

import styled from "styled-components"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  padding: 32px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

function Fundraising({ data }) {
  return (
    <SubLayout>
      <Container heading="Fundraising.heading">
        <Card>
          <SchoolCard
            img={data.medis.childImageSharp.fluid}
            h={<FormattedMessage id={"Fundraising.card0.h"} />}
            p={<FormattedMessage id={"Fundraising.card0.p"} />}
          ></SchoolCard>
          <SchoolCard
            img={data.cake.childImageSharp.fluid}
            h={<FormattedMessage id={"Fundraising.card1.h"} />}
            p={<FormattedMessage id={"Fundraising.card1.p"} />}
          ></SchoolCard>
          <SchoolCard
            img={data.congress.childImageSharp.fluid}
            h={<FormattedMessage id={"Fundraising.card2.h"} />}
            p={<FormattedMessage id={"Fundraising.card2.p"} />}
          ></SchoolCard>
        </Card>
      </Container>
    </SubLayout>
  )
}

export default Fundraising

export const query = graphql`
  query {
    medis: file(relativePath: { eq: "fundraising/medis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cake: file(relativePath: { eq: "fundraising/cake.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    congress: file(relativePath: { eq: "fundraising/congress.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
